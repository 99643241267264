<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th>Nom complet</th>
          <th>E-mail</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="index++">
          <td>
            <div class="fw-bold">
              {{ user.name }}
            </div>
          </td>

          <td>
            <div class="dw-bold">
              {{ user.email }}
            </div>
          </td>
          <td>
            <div class="d-flex">
              <button
                @click="
                  $router.push({
                    name: 'users-edit',
                    params: {
                      id: user.id,
                    },
                  })
                "
                class="btn btn-info mx-1"
              >
                Modifier
              </button>

              <button @click="remove(user.id)" class="btn btn-danger mx-1">
                Supprimer
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("user/fetch");
  },
  computed: {
    ...mapGetters("user", { users: "getUsers" }),
  },
  methods: {
    remove(id) {
      this.$store.dispatch("user/destroy", id);
    },
  },
};
</script>
