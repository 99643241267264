var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.users),function(user,index){return _c('tr',{key:index++},[_c('td',[_c('div',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(user.name)+" ")])]),_c('td',[_c('div',{staticClass:"dw-bold"},[_vm._v(" "+_vm._s(user.email)+" ")])]),_c('td',[_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-info mx-1",on:{"click":function($event){return _vm.$router.push({
                  name: 'users-edit',
                  params: {
                    id: user.id,
                  },
                })}}},[_vm._v(" Modifier ")]),_c('button',{staticClass:"btn btn-danger mx-1",on:{"click":function($event){return _vm.remove(user.id)}}},[_vm._v(" Supprimer ")])])])])}),0)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Nom complet")]),_c('th',[_vm._v("E-mail")]),_c('th')])])
}]

export { render, staticRenderFns }